import React from 'react';
import AdminHeader from './AdminHeader';
import AdminContent from './AdminContent';
import '../css/admin.css'; // Optional: Add styles for the admin page

const AdminPage = ({jsonData}) => {

  return (
    <div className="admin-page">
        <AdminHeader data={jsonData}/>
        <div className="admin-body">
            <AdminContent data={jsonData} />
        </div>
    </div>
  );
};

export default AdminPage;