import React, { useState, useEffect, useRef } from 'react';
import '../css/page.css';
import gsap from 'gsap';

function Page({ title, jsonData }) {

  const imgRef = useRef(null);
  const pageImageRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = `/${jsonData[0].image}`;
    img.onload = handleImageLoad;

    return () => {
      img.onload = null; // Clean up the event handler
    };
  }, [jsonData]);

  useEffect(() => {
    if (imageLoaded) {
      const tl = gsap.timeline();
      tl.fromTo(imgRef.current, 
        { opacity: 0}, { 
          opacity: 1, 
          duration: 2, 
          ease: 'power3.out',
          onStart: () => {
            imgRef.current.style.opacity = 0;
            pageImageRef.current.classList.remove('loading');
            imgRef.current.classList.remove('hide');
          }
        }
      )
    }
    else{
      imgRef.current.classList.add('hide');
      imgRef.current.style.opacity = 0;
      pageImageRef.current.classList.add('loading');
    }
  }, [imageLoaded]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="page">
        <div className="page-image" ref={pageImageRef}>
          <img src={`/${jsonData[0].image}`} alt={jsonData[0].title} ref={imgRef} />
        </div>
        <div className="page-copy">
            <div className="page-copyBlock">
                <h1>{title}</h1>
                <p dangerouslySetInnerHTML={{ __html: jsonData[0].text }}></p>
            </div>
        </div>
    </div>
  );
}   

export default Page;