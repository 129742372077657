const AdminItem = (data) => {
    return (
        <div className="admin-item">
            <ul className="admin-item-thumbs">
                {data.images.map((image, index) => (
                    <li key={index}>
                        <img src={`../${image}`} alt={`${data.title} ${index + 1}`} />
                    </li>
                ))}
            </ul>
            <p>
                { data.title }
                <br />
                {`${data.width} x ${data.height} inches` }
                <br />
                { data.medium }
                <br />
                { `$${data.price}` }  
            </p>
            <div className="admin-item-buttons">
                <button className="edit-button">Edit</button>
                <div className="arrow-buttons">
                    <button className="arrow-button" id="up-arrow-button">↑</button>
                    <button className="arrow-button" id="down-arrow-button">↓</button>
                </div>
            </div>
            
        </div>
    );
};

export default AdminItem;