import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';

import Navigation from './components/Navigation';
import Header from './components/Header';
import Footer from './components/Footer';
import Works from './components/Works';
import Page from './components/Page';
import Collage from './components/Collage';
import AdminPage from './admin/AdminPage';

import './css/app.css';


function App() {
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);

  // fix for vh units on mobile browsers
  function setVhUnits() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setVhUnits();
  window.addEventListener('resize', setVhUnits);

  // fetch JSON data
  useEffect(() => {
    fetch('/content.json')
      .then((response) => response.json())
      .then((data) => {
        setJsonData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching JSON:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className='pre loading'>Loading...</div>;
  }

  return (
    <Router>
      <AppContent jsonData={jsonData} />
    </Router>
  );
};

const AppContent = ({ jsonData }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  // Reorder jsonData.works based on the 'width' and 'height' parameters
  const sortedWorks = [...jsonData.works].sort((a, b) => {
    const areaA = a.width * a.height;
    const areaB = b.width * b.height;
    return areaB - areaA; // Sort in descending order
  });

  // Randomly sort jsonData.works
  //const sortedWorks = [...jsonData.works].sort(() => Math.random() - 0.5);

  // navigation panel animations
  const openMenu = () => {
    const nav = document.querySelector('nav');
    const overlay = document.querySelector('.overlay');

    const handleClickOutside = (event) => {
      if (event.target === overlay) {
        console.log('click overlay');
        closeMenu();
        document.removeEventListener('click', handleClickOutside);
      }
    };

    const timeline = gsap.timeline();
    timeline.from(nav, { 
      x: '100%', 
      duration: 0.5, 
      ease: 'power2.out',
      onStart: () => {
        nav.classList.remove('hide');
        document.addEventListener('click', handleClickOutside);
      }, 
    })
    .to(overlay, { 
      opacity: 0.25, 
      duration: 0.25, 
      onStart: () => {
        overlay.style.visibility = 'visible'; 
      }
    }, '<')
  }
  
  const closeMenu = () => {
    const nav = document.querySelector('nav');
    const overlay = document.querySelector('.overlay');
    
    const timeline = gsap.timeline();
    timeline.to(nav, { 
      x: '100%', 
      duration: 0.3, 
      ease: 'power3.out',
      onComplete: () => {
        nav.classList.add('hide');
        gsap.set(nav, { x: 0 });
      }
    })
    .to(overlay, { 
      opacity: 0, 
      duration: 0.25, 
      onComplete: () => {
        overlay.style.visibility = 'hidden'; 
      }
    }, '<');
  }

  return (
    <div className="App">
      {!isAdminRoute && <Navigation closeMenu={closeMenu} />}
      <div className="overlay"></div>
      {!isAdminRoute && <Header openMenu={openMenu} />}
      <main>
        <Routes>
          <Route path="/" element={<Works jsonData={sortedWorks} bioData={jsonData.bio} />} />
          <Route path="/about" element={<Page title="Artist Statement" jsonData={jsonData.about} />} />
          <Route path="/contact" element={<Page title="Contact Info" jsonData={jsonData.contact} />} />
          <Route path="/collages/*" element={<Collage jsonData={sortedWorks} />} />
          <Route path="/admin/*" element={<AdminPage jsonData={jsonData}/>} />
        </Routes>
      </main>
      {!isAdminRoute && <Footer />}
    </div>
  );
};

export default App;
