import React, { useRef, useEffect } from 'react';
import Thumb from './Thumb';

import '../css/works.css';

function Works({ jsonData, bioData }) {
  const worksContainerRef = useRef(null);
  const minColumnWidth = 350; // Minimum width for each column

  // Update grid columns on window resize
  useEffect(() => {
    function updateGridColumns() {
      if (worksContainerRef.current) {
        const containerWidth = worksContainerRef.current.clientWidth;
        const numberOfColumns = Math.floor(containerWidth / minColumnWidth);
    
        // Get all .thumb elements
        const thumbElements = worksContainerRef.current.querySelectorAll('.thumb');
    
        // Remove 'last-row' and 'last-in-row' classes from all .thumb elements
        thumbElements.forEach(thumb => {
          thumb.classList.remove('last-row');
          thumb.classList.remove('last-in-row');
        });
    
        // Calculate the start index of the last row
        const totalItems = thumbElements.length;
        const numberOfRows = Math.ceil(totalItems / numberOfColumns);
        const startIndexOfLastRow = (numberOfRows - 1) * numberOfColumns;
    
        // Add 'last-row' class to .thumb elements in the last row
        for (let i = startIndexOfLastRow; i < totalItems; i++) {
          thumbElements[i].classList.add('last-row');
        }
    
        // Add 'last-in-row' class to the last .thumb element in each row
        for (let i = numberOfColumns - 1; i < totalItems; i += numberOfColumns) {
          thumbElements[i].classList.add('last-in-row');
        }
      }
    }

    // Initial update
    updateGridColumns();

    // Update on window resize
    window.addEventListener('resize', updateGridColumns);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateGridColumns);
    };
  }, []);

  return (
    <>
      <div className="tout border-bottom">
        <h1>{bioData[0].text}</h1>
      </div>
      <div className="works" ref={worksContainerRef}>
          {jsonData.map((item, index) => (
            <Thumb 
              key={index} 
              data={item} 
              id={index} 
            />
          ))}
      </div>
    </>

  );
}   

export default Works;