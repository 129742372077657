import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../css/header.css';

function Header({ openMenu }) {
  const location = useLocation();
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isAtTop, setIsAtTop] = useState(true);
  const headerRef = useRef(null);

  // Set scroll position on page load
  useEffect(() => {

    if(location.pathname === '/about' || location.pathname === '/contact') {
      // reset homepage to top if they navigate to a subpage
      localStorage.setItem('scrollPosition', 0);
    }

    // Restore scroll position when returning to the homepage
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }
    else {   // for all other pages
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  // Update header classes based on location (for sticky header on home page)
  useEffect(() => {
    if (location.pathname === '/') { // For homepage only
      document.querySelector('header').classList.add('fancy');
      document.querySelector('main').classList.add('fancy');
    }

    return () => {
      if (location.pathname === '/') {
        document.querySelector('header').classList.remove('fancy');
        document.querySelector('header').classList.remove('sticky');
        document.querySelector('header').classList.remove('hidden');
        document.querySelector('main').classList.remove('fancy');
      }
    };

  }, [location.pathname]);

  // Scroll position saving
  useEffect(() => {
    const handleScroll = () => {
      
      // determine scroll direction
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setIsScrollingUp(false);
      } else {
        // Scrolling up
        setIsScrollingUp(true);
      }
      setIsAtTop(currentScrollTop <= 15);
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);

      // Save scroll position when navigating away from the homepage
      if (location.pathname === '/') {
        localStorage.setItem('scrollPosition', currentScrollTop);
      }
    };

    if (location.pathname === '/') { // For homepage only
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (location.pathname === '/') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [lastScrollTop, location.pathname]);

  // Sticky header
  useEffect(() => {
    if (isAtTop) {
      headerRef.current.classList.remove('sticky');
      headerRef.current.classList.remove('hidden');
    } else if (isScrollingUp) {
      headerRef.current.classList.add('sticky');
      headerRef.current.classList.remove('hidden');
    } else {
      headerRef.current.classList.remove('sticky');
      headerRef.current.classList.add('hidden');
    }
  }, [isScrollingUp, isAtTop]);

  // Menu button
  useEffect(() => {
    const btnMenu = document.getElementById('btn-menu');

    const handleOpenMenu = (event) => {
      event.preventDefault();
      openMenu();
    };

    btnMenu.addEventListener('click', handleOpenMenu);
    btnMenu.addEventListener('touchstart', handleOpenMenu);

    return () => {
      btnMenu.removeEventListener('click', handleOpenMenu);
      btnMenu.removeEventListener('touchstart', handleOpenMenu);
    };
  }, [openMenu]);

  return (
      <header className='border-bottom' ref={headerRef}>
      <div className="back-button-container hide">
        <Link to="/" id="btn-back"></Link>
      </div>
      <div id="header-content">
        {location.pathname === '/' ? (
          <div id="Title">
            <h2>
              Rosemary Carmen Smith
              <br />
              Collage Artist
            </h2>
          </div>
        ) : (
          <Link to="/" id="Title">
            <h2>
              Rosemary Carmen Smith
              <br />
              Collage Artist
            </h2>
          </Link>
        )}
      </div>
      <div id="btn-menu"></div>
    </header>
  );
}

export default Header;