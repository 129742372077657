import React from 'react';
import AdminItem from './AdminItem';

const AdminContent = ({data}) => {

    return (
        <main className="admin-content">
            <ul>
                {data.works.map((work, index) => (
                    <li key={index}>
                        <AdminItem {...work} />
                    </li>
                ))}
            </ul>
        </main>
    );
};

export default AdminContent;