import React from 'react';

const AdminHeader = ({data}) => {

  return (
    <header className="admin-header">
      <h1>
        RoseCutsPaper.com
        <br />
        Last Updated: {data.version}
      </h1>
    </header>
  );
};

export default AdminHeader;