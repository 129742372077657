import React from 'react';

import '../css/footer.css';

function Footer() {

  const handleInstaClick = () => {
    window.open('https://www.instagram.com/rosecutspaper', '_blank');
  };

  return (
    <footer className='border-top'>
      <div className='footer-content'>
        <h3>© 2024</h3>
        <div id="btn-Insta" onClick={handleInstaClick}></div>
      </div>
      <div className="artwork-info hide">
        <h2 className="artwork-title">Title</h2>
        <div className="artwork-nav"></div>
        <h2 className="artwork-dimensions">Dimensions</h2>
      </div>
    </footer>
  );
}

export default Footer;