import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import '../css/page.css';
import '../css/collage.css';

function Collage({ jsonData }) {
  // Get the query parameters from the URL to determine which collage to display
  const location = useLocation(); // Get the location object
  const queryParams = new URLSearchParams(location.search); // Parse the query parameters
  const id = queryParams.get('id'); // Get the 'id' parameter from the query string
  const decodedId = decodeURIComponent(id); // Decode the id
  const data = jsonData[decodedId]; // Get the data for the collage

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);
  const loaderRef = useRef(null);

  // Drag gesture handling
  const startX = useRef(0);
  const currentX = useRef(0);
  const isDragging = useRef(false);

  ///// IMAGE LOADING /////

  const backgroundImageStyle = {
    backgroundImage: `url(../${data.images[currentImageIndex]})`,
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  ///// IMAGE TRANSITIONS /////

  const animateImageIn = () => {

    if (imageRef.current) {
      gsap.fromTo(
        imageRef.current,
        { opacity: 0, x: 0 },
        { opacity: 1, duration: 0.5, ease: 'power3.out' }
      );
    }
  };

  const animateImageOut = useCallback((onComplete) => {
    if (imageRef.current) {
      gsap.to(imageRef.current, {
        opacity: 0,
        duration: 0.1,
        ease: 'power3.out',
        onComplete: onComplete
      });
    }
  }, []);

  ///// CLICKS AND DRAG EVENTS /////

  const handleImageClick = useCallback(() => {
    animateImageOut(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.images.length);
      setImageLoaded(false);
    });
  }, [animateImageOut, data.images.length]);


  const handleDragEnd = useCallback((direction) => {
    if (direction === 'left') {
      animateImageOut(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.images.length);
        setImageLoaded(false); // Reset imageLoaded to false for the new image
      });
    } else if (direction === 'right') {
      animateImageOut(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + data.images.length) % data.images.length);
        setImageLoaded(false); // Reset imageLoaded to false for the new image
      });
    }
  }, [data.images.length, animateImageOut]);

  const handleDragStart = (e) => {
    startX.current = e.type === 'mousedown' ? e.clientX : e.touches[0].clientX;
    isDragging.current = true;
  };

  const handleDragMove = (e) => {
    if (!isDragging.current) return;
    currentX.current = e.type === 'mousemove' ? e.clientX : e.touches[0].clientX;
    const deltaX = currentX.current - startX.current;
    if (imageRef.current) {
      imageRef.current.style.transform = `translateX(${deltaX}px)`;
    }
  };

  const handleDragEndEvent = useCallback(() => {
    if (!isDragging.current) return;
    isDragging.current = false;
    const deltaX = currentX.current - startX.current;
    if (Math.abs(deltaX) > 50) {
      handleDragEnd(deltaX > 0 ? 'right' : 'left');
    } else {
      if (imageRef.current) {
        imageRef.current.style.transform = 'translateX(0)';
      }
    }
  }, [handleDragEnd]);

  const handleTouchEnd = useCallback((e) => {
    if (!isDragging.current) {
      handleImageClick();
    } else {
      handleDragEndEvent();
    }
  }, [handleImageClick, handleDragEndEvent]);

  ///// EFFECTS /////

  useEffect(() => {
    // remove borders from header and footer
    document.querySelector('header').classList.remove('border-bottom');
    document.querySelector('footer').classList.remove('border-top');

    // disable scroll
    document.body.style.overflow = 'hidden';

    // hide the title, show the arrow
    document.querySelector('header #Title').style.opacity = 0;
    document.querySelector('header .back-button-container').classList.remove('hide');
    document.querySelector('footer .footer-content').classList.add('hide');

    // Add nav-dots for each image
    const artworkNav = document.querySelector('.artwork-nav');
    artworkNav.innerHTML = ''; // Clear any existing nav-dots
    data.images.forEach((_, index) => {
      const navBlock = document.createElement('div');
      const navDot = document.createElement('div');
      navBlock.className = 'nav-block';
      navDot.className = 'nav-dot';
      if (index === currentImageIndex) {
        navBlock.classList.add('current');
      } else {
        navBlock.addEventListener('click', () => {
          setCurrentImageIndex(index);
          setImageLoaded(false); // Reset imageLoaded to false for the new image
        });
      }
      navBlock.appendChild(navDot);
      artworkNav.appendChild(navBlock);
    });

    // show the artwork info
    document.querySelector('.artwork-title').textContent = data.title;
    document.querySelector('.artwork-dimensions').textContent = `${data.width} x ${data.height}`;
    document.querySelector('.artwork-info').classList.remove('hide');

    // cleanup functions for unmounting
    return () => {
      // add borders to header and footer
      document.querySelector('header').classList.add('border-bottom');
      document.querySelector('footer').classList.add('border-top');

      // enable scroll
      document.body.style.overflow = 'auto';

      // show the title, hide the arrow
      document.querySelector('header .back-button-container').classList.add('hide');
      document.querySelector('header #Title').style.opacity = 1;
      document.querySelector('footer .footer-content').classList.remove('hide');

      // hide the artwork info
      document.querySelector('.artwork-info').classList.add('hide');

      // Clear nav-dots
      artworkNav.innerHTML = '';
    };
  }, [data.title, data.width, data.height, data.images, currentImageIndex]);

  useEffect(() => {
    const img = new Image();
    img.src = `../${data.images[currentImageIndex]}`;
    img.onload = handleImageLoad;

    return () => {
      img.onload = null; // Clean up the event handler
    };
  }, [currentImageIndex, data.images]);

  useEffect(() => {
    if (imageLoaded) {
      loaderRef.current.classList.add('hide');
      animateImageIn();
    } else {
      imageRef.current.style.opacity = 0;
      imageRef.current.style.transform = 'translateX(0)';
      loaderRef.current.classList.remove('hide');
    }
  }, [imageLoaded]); 
 
 
  useEffect(() => {
    const imageElement = imageRef.current;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (imageElement) {
      if (!isMobile) {
        imageElement.addEventListener('mousedown', handleDragStart);
        imageElement.addEventListener('mousemove', handleDragMove);
        imageElement.addEventListener('mouseup', handleDragEndEvent);
        imageElement.addEventListener('mouseleave', handleDragEndEvent);
      }
      imageElement.addEventListener('touchstart', handleDragStart);
      imageElement.addEventListener('touchmove', handleDragMove);
      imageElement.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (imageElement) {
        if (!isMobile) {
          imageElement.removeEventListener('mousedown', handleDragStart);
          imageElement.removeEventListener('mousemove', handleDragMove);
          imageElement.removeEventListener('mouseup', handleDragEndEvent);
          imageElement.removeEventListener('mouseleave', handleDragEndEvent);
        }
        imageElement.removeEventListener('touchstart', handleDragStart);
        imageElement.removeEventListener('touchmove', handleDragMove);
        imageElement.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [handleDragEndEvent, handleTouchEnd]);

  return (
    <>
      <div className="loading full-loader" ref={loaderRef}></div>
      <div
        className="full-image"
        style={backgroundImageStyle}
        ref={imageRef}
      ></div>
    </>
  );
}

export default Collage;