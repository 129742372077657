import React from 'react';
import { Link } from 'react-router-dom';

import '../css/navigation.css';

function Navigation({closeMenu, jsonData}) {
  
    return (
    <nav className='hide'>
      <div id="btn-close" onClick={closeMenu}></div>
      <div id='nav-content'>
        <ul>
          <li><Link to="/" onClick={closeMenu}><h2>Portfolio</h2></Link></li>
          <li><Link to="/about" onClick={closeMenu}><h2>Artist Statement</h2></Link></li>
          <li><Link to="/contact" onClick={closeMenu}><h2>Contact Info</h2></Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;